<template>
    <CCard class="projects-list">
        <teleport v-if="mountedComponent" to="#header-nav">
            <CBreadcrumbItem active>Projects</CBreadcrumbItem>
        </teleport>

        <teleport v-if="mountedComponent" to="#projects-header">
            <h1 class="projects-list__heading">Projects</h1>
        </teleport>

        <CCardBody>
            <CRow class="w-100 mx-0 mb-3">
                <CCol class="GCFilterMaxWidth GCFilter col-12">
                    <GCFilter component="Projects" @search="(val) => onTimeoutSearch(() => fetchDataProjects(val))"/>
                </CCol>
            </CRow>
            <SmartTable :items="projects" :items-per-page="parseInt(perPage)" :columns="fields" :sorter-value="sort"
                        :selected-items="selected" :loading="loadingTable" :active-page="activePage" :per-page="perPage"
                        :clear-text="search?.length ? 'No search results found' : 'No Data yet'" :pages="pages"
                        @sorter-change="onSort" @selected-items-change="(s) => (selected = s)"
                        @update-active-page="(value) => (activePage = value)"
                        @update-per-page="(value) => (perPage = value)">
                <template #clear_text>
                    <div class="table__clear d-flex align-items-center justify-content-center mb-2 w-100">
                        <template v-if="search.length">
                            <div class="table__clear-no-results">No search results found</div>
                        </template>
                        <template v-else>
                            <CIcon class="table__clear__icon" size="6xl" name="cilClipboard"/>
                            <div class="table__clear__title">No projects yet</div>
                            <div class="table__clear__title-sub">
                                Add projects you have created will be displayed here.
                            </div>
                            <CButton color="primary" class="table__clear__btn" @click="handleCreateProject">
                                Create a project
                            </CButton>
                        </template>
                    </div>
                </template>
            </SmartTable>
        </CCardBody>
    </CCard>
    <AppModal title="Welcome to ContractsConnected!" :visible="welcomeModal" @close="toggleWelcomeModal">
        <iframe src="https://www.youtube.com/embed/6mOjCTS7E28?si=T5TIaE1OSD_p_OgY"
                title="Welcome to ContractsConnected!" class="welcome-video"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    </AppModal>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import CInputDateRange from '@/components/CInputDateRange'
import CSelectSearch from '@/components/CSelectSearch'
import table from '@/mixin/table'
import GCFilter from '@/components/GCFilter/index'
import breadcrumbsMixin from '../breadcrumbsMixin'
import {notifyByPlan} from '@/services/Notify/Toasts'
import Gate from '@/services/Gate/Gate'
import onTimeoutSearch from '@/mixin/searchTimeout'
import {SUB_CONTRACTOR_TYPE} from "@/utils/constants/plans";
import AppModal from "@/components/Modals/AppModal.vue";

export default {
    name: 'ProjectsList',
    mixins: [table, breadcrumbsMixin, onTimeoutSearch],
    components: {AppModal, CInputDateRange, CSelectSearch, GCFilter},
    data() {
        return {
            welcomeModal: false,
            fields: [
                {
                    key: 'project_name',
                    label: 'Project Name',
                    _style: 'width: 400px',
                    link: {
                        name: 'ProjectDashboard',
                        params: {id: 'id'},
                        iconNewTab: true,
                    },
                },
                {
                    key: 'company_name',
                    label: 'Company Name',
                    _style: '',
                    link: {name: 'CompanyInfo', params: {id: 'company_id'}},
                },
                {key: 'internal_number', label: 'Project Number', _style: ''},
                {key: 'owner_name', label: 'Owner Name', _style: ''},
                {key: 'stage', label: 'Stage', _style: ''},
                {key: 'estimated_start_date', label: 'Start Date', _style: ''},
                {key: 'estimated_end_date', label: 'End Date', _style: ''},
                {
                    key: 'manager_full_name',
                    label: 'Project Manager',
                    _style: '',
                    link: {
                        name: 'CompanyUser',
                        params: {user_id: 'manager_id', id: 'company_id'},
                    },
                },
                {key: 'manager_phone', label: 'Manager Phone', _style: ''},
                {
                    key: 'manager_email',
                    label: 'Manager Email',
                    _style: 'width: 100px',
                    email: true,
                },
            ],
            tempFilter: {},
        }
    },
    computed: {
        ...mapGetters(['user', 'projects']),
    },
    created() {
        this.$nextTick(() => {
            this.tempFilter.search = this.$route.query.search || ''
        });
    },
    mounted() {
        this.checkIfFirstSubsLoginAndShowVideo();
    },
    methods: {
        ...mapActions(['fetchProjects']),
        async handleCreateProject() {
            ;(await Gate.can('create', 'project'))
                ? this.$router.push({name: 'ProjectNew'})
                : notifyByPlan()
        },
        fetchDataProjects(filter = {}) {
            this.tempFilter = this.$deepClone(filter)
            this.fetchData()
        },
        fetchData() {
            this.loadingTable = true
            this.fetchProjects({
                params: {
                    ...this.tempFilter,
                    per_page: this.perPage,
                    page: this.activePage,
                    sort_field: this.sort && this.sort.column,
                    sort_direction: this.sort && this.sort.state,
                },
            })
                .then((res) => {
                    this.pages = res.meta.last_page
                    if (this.activePage > this.pages) {
                        this.activePage = this.activePage - 1
                    }
                })
                .finally(() => (this.loadingTable = false))
        },
        checkIfFirstSubsLoginAndShowVideo() {
            if (this.user.last_activity === null && this.user.company.type === SUB_CONTRACTOR_TYPE) {
                this.toggleWelcomeModal();
            }
        },
        toggleWelcomeModal() {
            this.welcomeModal = !this.welcomeModal;
        }
    },
}
</script>

<style lang="scss">
@import 'style';
</style>
<style lang="scss" scope>
.projects-list {
    &__heading {
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.2px;
        color: #1c262f;
    }
}

.table__clear {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 30px 30px 60px;
    background: #f5f7f9;

    &-no-results {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-size: 18px;
        min-height: 146px;
    }

    &__icon {
        color: #b1b7c1;
        margin-bottom: 20px;
    }

    &__title {
        margin-bottom: 12px;
        font-weight: 700;
        font-size: 18px;
        color: #303c54;

        &-sub {
            margin-bottom: 35px;
            font-weight: 400;
            font-size: 14px;
            color: #465967;
        }
    }
}

.card-body {
    border-radius: 0.25rem !important;
}

.card .card-header {
    border-bottom: none !important;
}

.GCFilter {
    padding-left: 0.25rem !important;
}

iframe.welcome-video {
    max-width: 1000px;
    width: 75vw;
    max-height: 562px;
    height: 60vh;
}
</style>
